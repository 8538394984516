import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fadeAnimation } from './common/others/animations';
import { environment } from 'src/environments/environment';
import { ThemeObservable } from './common/observables/theme.observable';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit {
  public lang: string | any;
  public theme: string | any = 'light';
  public currentRoute: string | any = 'home';
  public displayLangPopup: boolean = false;
  public allLangs: string[] = environment.allLangs;
  public displayMainOverlay: boolean = true;
  public willDismissMainOverlay: boolean = false;

  constructor(private translateService: TranslateService,
    private router: Router,
    private themeObservable: ThemeObservable)
  {
    translateService.addLangs(this.allLangs);
    translateService.setDefaultLang('en');

    // Get navigator language & set it if not already set
    this.lang = localStorage.getItem('lang') || navigator.language.slice(0, 2);
    localStorage.setItem('lang', this.lang);
    translateService.use(this.lang);

    // Get current route
    this.router.events.subscribe((val) => {
      if(this.router.url == '/') {
        this.currentRoute = '/home';
      } else this.currentRoute = this.router.url;
    });

    // Set theme
    this.theme = localStorage.getItem('theme') || 'light';
    if(this.theme == 'dark') document.body.classList.add('dark-theme');
    localStorage.setItem('theme', this.theme);
  }

  public async ngOnInit() {
    setTimeout(() => {
      this.willDismissMainOverlay = true;
    }, 250)

    setTimeout(() => {
      this.displayMainOverlay = false;
    }, 500)
  }

  // Change language
  public changeLang(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem('lang', lang);
    this.lang = lang;
    setTimeout(() => {
      this.displayLangPopup = false;
    }, 350)
  }

  // Change theme
  public toogleChangeTheme() {
    if(this.theme == 'light') {
      this.theme = 'dark';
      document.body.classList.add('dark-theme');
    } else {
      this.theme = 'light';
      document.body.classList.remove('dark-theme');
    }
    localStorage.setItem('theme', this.theme);
    this.themeObservable.updateValue();
  }
}
