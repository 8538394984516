import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeObservable {
  constructor() {}

  private updateThemeValue = new BehaviorSubject<any>(false);
  subjectUpdateThemeValue = this.updateThemeValue.asObservable();

  // Trigger the update of the theme value
  public async updateValue() {
    this.updateThemeValue.next(true);
  }
}
