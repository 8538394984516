import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeAnimation, slideLeftAnimation } from '../common/others/animations';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ThemeObservable } from '../common/observables/theme.observable';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../app.component.scss'],
  animations: [fadeAnimation, slideLeftAnimation]
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  public resizeObserver: ResizeObserver | any;
  public hasSmallDevice: boolean = false;
  public imageWidth: number = 0;
  public subtitlesWidth: string = '100%';
  public lang: string | any = localStorage.getItem('lang') || 'en';
  public theme: string = localStorage.getItem('theme') || 'light';
  public langChangeEvent$: Subscription;
  public themeChangeEvent$: Subscription;
  public baseImageMargin: number = 0;
  public baseSubtitlesMargin: number = 0;
  public imageMargin: number = 0;
  public subtitlesMargin: number = 0;
  public deviceSide: string = 'left';
  public audioWaveData = [0, 1, 1, 2, 3, 4, 4, 1, 2, 3, 3, 3, 2, 2, 2, 1, 1, 1, 3, 4, 4, 4, 3, 3, 4, 2, 2, 2, 1, 1, 1, 1, 2, 2, 2, 3, 3, 1, 1, 1]
  public audioWaveDataReminder = [0, 1, 2, 2, 2, 1, 1, 3, 3, 2, 3, 4, 4, 2, 2, 1, 1, 2, 3, 4, 4, 4, 3, 3, 4, 2, 2, 2, 1, 1, 1, 1, 2, 2, 2, 3, 3, 1, 1, 1]
  public audioWaveArray = new Array();
  public audioWaveArrayReminder = new Array();

  @ViewChild('audioRangeWidth') audioRangeWidth: ElementRef;
  @ViewChild('audioRangeWidthReminder') audioRangeWidthReminder: ElementRef;

  public messageTextDate: Date = new Date('2024-09-01T12:11:00');
  public messageAudioDate: Date = new Date('2024-09-01T12:12:00');
  public messageImageDate: Date = new Date('2024-09-01T12:13:00');

  constructor(private host: ElementRef,
    private zone: NgZone,
    private translateService: TranslateService,
    private themeObservable: ThemeObservable)
  {
    this.themeChangeEvent$ = this.themeObservable.subjectUpdateThemeValue.subscribe(() => {
      this.theme = localStorage.getItem('theme') || 'light';
    });

    this.langChangeEvent$ = this.translateService.onLangChange.subscribe((event) => {
      this.lang = event.lang;
    });
  }

  ngOnInit(): void {
    this.lang = this.translateService.currentLang;

    this.resizeObserver = new ResizeObserver(entries => {
      this.zone.run(() => {
        this.hasSmallDevice = entries[0].contentRect.width < 650;
        this.imageWidth = 0.0625 * (entries[0].contentRect.width * 1.8);
        this.baseImageMargin = 0.0625 * (entries[0].contentRect.width) / 1.58;
        this.baseSubtitlesMargin = 0.0625 * (entries[0].contentRect.width) / 1.08;

        if(this.hasSmallDevice) {
          this.subtitlesWidth = (0.0625 * (entries[0].contentRect.width) * 2) + 'rem';
        } else {
          this.subtitlesWidth = '100%';
          this.subtitlesMargin = 0;
        }

        this.adaptVoiceMessageWave()
      });
    });

    this.resizeObserver.observe(this.host.nativeElement.firstElementChild);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adaptVoiceMessageWave()
    }, 500);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.host.nativeElement);
    this.themeChangeEvent$.unsubscribe();
    this.resizeObserver.disconnect();
    this.langChangeEvent$.unsubscribe();
  }

  public toogleDevice() {
    if(this.deviceSide == 'left') {
      this.deviceSide = 'right'
      this.imageMargin = this.baseImageMargin;
      this.subtitlesMargin = this.baseSubtitlesMargin;
    } else {
      this.imageMargin = 0;
      this.subtitlesMargin = 0;
      this.deviceSide = 'left'
    }
  }

  public adaptVoiceMessageWave() {
    const width = this.audioRangeWidth.nativeElement.clientWidth;
    if(width > 0) {
      const linesNumber = Math.round(width/4.8);
      this.audioWaveArray = new Array(linesNumber);
    }

    const reminderWidth = this.audioRangeWidthReminder.nativeElement.clientWidth;
    if(width > 0) {
      const linesNumber = Math.round(reminderWidth/4.9);
      this.audioWaveArrayReminder = new Array(linesNumber);
    }
  }
}
