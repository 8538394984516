import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fadeAnimation } from '../common/others/animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['../app.component.scss'],
  animations: [fadeAnimation]
})
export class TermsComponent implements OnInit, OnDestroy {
  public lang: string | any = localStorage.getItem('lang');
  public langChangeEvent$: Subscription;
  public lastEditedDate: Date = new Date('2024-12-07');
  public displayContent: boolean = false;

  constructor(private translateService: TranslateService) {
    this.langChangeEvent$ = translateService.onLangChange.subscribe((event) => {
      this.lang = event.lang;
    });
  }

  ngOnInit(): void {
    this.lang = this.translateService.currentLang;
  }

  ngOnDestroy(): void {
    this.langChangeEvent$.unsubscribe();
  }
}
