import { trigger, transition, style, animate, state } from "@angular/animations";

export const fadeAnimation = trigger('fadeAnimation', [
  transition(
    ':enter', [
      style({ opacity: 0 }),
      animate('0.15s ease-out', style({ opacity: 1 }))
  ]),
  transition(
    ':leave', [
      style({ opacity: 1 }),
      animate('0.15s ease-in', style({ opacity: 0 }))
  ])
]);

export const fadeSlowAnimation = trigger('fadeSlowAnimation', [
  transition(':enter', [
      style({ opacity: 0 }),
      animate('0.20s ease-out', style({ opacity: 1 }))
  ]),
  transition(':leave', [
      style({ opacity: 1 }),
      animate('0.20s ease-in', style({ opacity: 0 }))
  ])
]);

export const buttonDownAnimation = trigger('buttonDownAnimation', [
  state('false', style({ transform: 'translateY(-0.1875rem)' })),
  state('true', style({ transform: 'translateY(0rem)' })),
  transition('false => true', animate('240ms ease-in')),
  transition('true => false', animate('240ms ease-out'))
]);

export const slideLeftAnimation = trigger('slideLeftAnimation', [
  transition(':enter', [
      style({ marginLeft: '-2.356rem', opacity: 0 }),
      animate('0.25s cubic-bezier(.17,.67,.83,.67)', style({ marginLeft: '0rem', opacity: 1 }))
  ]),
  transition(':leave',[
      style({ marginLeft: '0rem', opacity: 1 }),
      animate('0.25s cubic-bezier(.17,.67,.83,.67)', style({ marginLeft: '-2.356rem', opacity: 0 }))
  ])
]);

export const bigSlideLeftAnimation = trigger('bigSlideLeftAnimation', [
  transition(':enter', [
      style({ marginLeft: '-4rem', opacity: 0 }),
      animate('0.2s cubic-bezier(.17,.67,.83,.67)', style({ marginLeft: '0rem', opacity: 1 }))
  ]),
  transition(':leave',[
      style({ marginLeft: '0rem', opacity: 1 }),
      animate('0.2s cubic-bezier(.17,.67,.83,.67)', style({ marginLeft: '-4rem', opacity: 0 }))
  ])
]);

export const slideUpAnimation = trigger('slideUpAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(100%)' }),
    animate(
      "0.3s cubic-bezier(0.64, 0.4, 0.12, 0.97)",
      style({
        transform: "translateY(0%)",
        opacity: 1,
      })
    )
  ]),
  transition(':leave', [
    animate(200, style({ opacity: 0, transform: 'translateY(100%)' }))
  ])
]);

export const betterSlideUpAnimation = trigger('betterSlideUpAnimation', [
  transition(':enter', [
      style({ marginBottom: '-3.125rem', opacity: 0 }),
      animate('0.20s ease-in-out', style({ marginBottom: '0rem', opacity: 1 }))
  ]),
  transition(':leave',[
      style({ marginBottom: '0rem', opacity: 1 }),
      animate('0.20s ease-in-out', style({ marginBottom: '-3.125rem', opacity: 0 }))
  ])
]);

export const slideUpMediasSelectionAnimation = trigger('slideUpMediasSelectionAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(100%)' }),
    animate(
      "0.3s cubic-bezier(0.64, 0.4, 0.12, 0.97)",
      style({
        transform: "translateY(0%)",
        opacity: 1,
      })
    )
  ]),
  transition(':leave', [
    animate(200, style({ opacity: 0 }))
  ])
]);

export const betterSlideDownAnimation = trigger('betterSlideDownAnimation', [
  transition(':enter', [
    style({ marginTop: '-5rem', opacity: 0 }),
    animate('0.25s ease-in-out', style({ marginTop: '0rem', opacity: 1 }))
  ]),
  transition(':leave',[
      style({ marginTop: '0rem', opacity: 1 }),
      animate('0.25s ease-in-out', style({ marginTop: '-5rem', opacity: 0 }))
  ])
]);

export const slideDownAnimation = trigger('slideDownAnimation', [
  transition(':enter', [
    style({ opacity: 0, height: '0rem' }),
    animate(
      "0.3s ease-in-out",
      style({
        height: '*',
        opacity: 1,
      })
    )
  ]),
  transition(':leave', [
    animate(200, style({ opacity: 0, height: '0rem' }))
  ])
]);

export const smoothAccordion = trigger('smoothAccordion', [
  state('initial', style({
    height: '0',
    opacity: '0',
    overflow: 'hidden',
    visibility: 'hidden',
  })),
  state('final', style({
    opacity: 1,
    visibility: 'visible',
  })),
  transition('initial<=>final', animate('250ms cubic-bezier(0.455, 0.03, 0.515, 0.955)'))
]);

export const slideUpMessageAnimation = trigger('slideUpMessageAnimation', [
  transition(':enter', [
    style({ opacity: 0, marginBottom: '-100%', transform: 'scale(0)', transformOrigin: 'bottom center' }),
    animate('0.5s ease-in-out', style({
      opacity: 1,
      marginBottom: '0rem',
      transform: 'scale(1)'
    }))
  ])
]);

export const slideDownMessageAnimation = trigger('slideDownMessageAnimation', [
  state('in', style({opacity: 1})),
  transition(':leave', [
    style({ opacity: 1, height: '*' }),
    animate('0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955)',
     style({
      opacity: 0,
      height: '0rem',
      marginTop: '0rem',
     }))
  ])
])
