<div class="content-container">
  <!-- Hero -->

  <div class="hero-container hero-container-padding hero-container-big-width">
    <p class="subtitle">{{ 'HOME.hero_tag' | translate }}</p>
    <h1>{{ 'HOME.hero_title' | translate }}</h1>
    <p class="paragraph">{{ 'HOME.hero_paragraph' | translate }}</p>
    <img [src]="'../../assets/svgs/download-apple-' + theme + '.svg'" alt="download-apple" class="img-download">
  </div>

  <!-- Device presentation -->

  <div class="devices-container">
    <img *ngIf="!hasSmallDevice" src="../../assets/svgs/iphone-ipad.svg" alt="iphone-ipad-preview" class="iphone-ipad-preview">
    <img *ngIf="hasSmallDevice" src="../../assets/svgs/iphone-ipad.svg" alt="iphone-ipad-preview" class="iphone-ipad-preview" [style]="'width:' + imageWidth + 'rem; margin-left: -' + imageMargin + 'rem;'">
    <div class="devices-subtitle-container" [style]="'width:' + subtitlesWidth + '; margin-left: -' + subtitlesMargin + 'rem;'">
      <div>
        <p class="small-title">{{ 'HOME.device_phone_title' | translate }}</p>
        <p class="subtilte subtitle-big ">
          {{ 'HOME.device_phone_subtitle' | translate }}
        </p>
      </div>
      <button *ngIf="hasSmallDevice" (click)="toogleDevice()" class="btn btn-light btn-small">
        <img *ngIf="deviceSide == 'left'" src="../../assets/icons/arrow-right.svg" alt="next-icon">
        <img *ngIf="deviceSide == 'right'" src="../../assets/icons/arrow-left.svg" alt="back-icon">
      </button>
      <div class="device-tablet">
        <p class="small-title">{{ 'HOME.device_tablet_title' | translate }}</p>
        <p class="subtilte subtitle-big">
          {{ 'HOME.device_tablet_subtitle' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Advantages -->

<div class="background-container-white">
  <div class="content-container content-container-white content-container-spaced">
    <div class="flex-content">
      <div class="flex-content-text">
        <div class="icon-header margin-bottom">
          <img src="../../assets/icons/message.svg">
        </div>
        <h2>{{ 'HOME.advantages_send_title' | translate }}</h2>
        <p class="paragraph">{{ 'HOME.advantages_send_subtitle' | translate }}</p>
      </div>
      <div class="flex-content-image">
        <div class="conversation-container">

          <!-- Text message -->
          <div class="message-container">
            <div class="message">
              <p>{{ 'HOME.advantages_img_message_paragraph'  | translate }}</p>

              <div class="subtitle-message">
                <div></div>
                <div>
                  <span class="subtitle" *ngIf="lang == 'en'">{{ messageTextDate | date:'shortTime':undefined:lang }}</span>
                  <span class="subtitle" *ngIf="lang != 'en'">{{ messageTextDate | date:'H:mm':undefined:lang | titlecase}}</span>
                  <div class="message-check">
                    <img class="message-check-no-filter" src="../../assets/icons/double-check-orange.svg">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Senior voice message -->
          <div class="message-container message-container-senior">
            <div class="message message-width">
              <div class="voice-message-container">
                <div class="audio-range-button">
                  <img src="../../assets/icons/play.svg">
                </div>
                <div class="wave-and-duration-container">
                  <div class="wave-range-container" #audioRangeWidth>
                    <div class="audio-wave-round"></div>
                    <div class="audio-wave-component">
                      <div class="wave"
                        *ngFor="let wave of audioWaveArray; let i=index; let first=first; let last=last;"
                        [class.wave-25]="audioWaveData[i] == 1"
                        [class.wave-50]="audioWaveData[i] == 2"
                        [class.wave-75]="audioWaveData[i] == 3"
                        [class.wave-75]="last || first">
                      </div>
                    </div>
                  </div>

                  <div class="duration-container">
                    <p class="paragraph-title">0:35</p>
                  </div>
                </div>
              </div>
              <div class="subtitle-message subtitle-message-audio">
                <div></div>
                <div>
                  <span class="subtitle" *ngIf="lang == 'en'">{{ messageAudioDate | date:'shortTime':undefined:lang }}</span>
                  <span class="subtitle" *ngIf="lang != 'en'">{{ messageAudioDate | date:'H:mm':undefined:lang | titlecase}}</span>
                  <div class="message-check">
                    <img class="message-check-no-filter" src="../../assets/icons/double-check-orange.svg">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="message-container">
            <!-- Text & image & video & gallery message -->
            <div class="message message-width message-media">
              <p class="subtitle-media subtitle">{{ 'HOME.advantages_img_message_paragraph_2'  | translate }}</p>

              <!-- Image/video message-->
              <div class="message-media-container">
                <img src="../../assets/images/message-image.jpg" class="message-image">
              </div>

              <div class="subtitle-message">
                <div></div>
                <div>
                  <span class="subtitle" *ngIf="lang == 'en'">{{ messageImageDate | date:'shortTime':undefined:lang }}</span>
                  <span class="subtitle" *ngIf="lang != 'en'">{{ messageImageDate | date:'H:mm':undefined:lang | titlecase}}</span>
                  <div class="message-check">
                    <img class="message-check-no-filter" src="../../assets/icons/double-check-orange.svg">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="background-container-white">
  <div class="content-container content-container-white content-container-spaced">
    <div class="flex-content">
      <div class="flex-content-text">
        <div class="icon-header margin-bottom">
          <img src="../../assets/icons/clock.svg">
        </div>
        <h2>{{ 'HOME.advantages_reminders_title' | translate }}</h2>
        <p class="paragraph">{{ 'HOME.advantages_reminders_subtitle' | translate }}</p>
      </div>
      <div class="flex-content-image">
        <div class="reminders-boxs-container">
          <div class="box">
            <p class="label">{{ 'HOME.advantages_reminder_label_message' | translate }}</p>
            <p class="subtitle">{{ 'HOME.advantages_reminder_message_1' | translate }}</p>
            <p class="label small-margin-top">{{ 'HOME.advantages_reminder_label_repetition' | translate }}</p>
            <p class="subtitle">{{ 'HOME.advantages_reminder_label_date' | translate }}</p>
          </div>
          <div class="box box-orange">
            <p class="label">{{ 'HOME.advantages_reminder_label_message' | translate }}</p>
            <div class="voice-message-container">
              <div class="audio-range-button">
                <img src="../../assets/icons/play.svg">
              </div>
              <div class="wave-and-duration-container">
                <div class="wave-range-container" #audioRangeWidthReminder>
                  <div class="audio-wave-round"></div>
                  <div class="audio-wave-component">
                    <div class="wave"
                      *ngFor="let wave of audioWaveArrayReminder; let i=index; let first=first; let last=last;"
                      [class.wave-25]="audioWaveDataReminder[i] == 1"
                      [class.wave-50]="audioWaveData[i] == 2"
                      [class.wave-75]="audioWaveData[i] == 3"
                      [class.wave-75]="last || first">
                    </div>
                  </div>
                </div>

                <div class="duration-container">
                  <p class="paragraph-title">1:02</p>
                </div>
              </div>
            </div>
            <p class="label small-margin-top">{{ 'HOME.advantages_reminder_label_repetition' | translate }}</p>
            <p class="subtitle">{{ 'HOME.advantages_reminder_label_date' | translate }}</p>
          </div>
          <div class="box box-last">
            <p class="label">{{ 'HOME.advantages_reminder_label_message' | translate }}</p>
            <p class="subtitle">{{ 'HOME.advantages_reminder_message_2' | translate }}</p>
            <p class="label small-margin-top">{{ 'HOME.advantages_reminder_label_repetition' | translate }}</p>
            <p class="subtitle">{{ 'HOME.advantages_reminder_label_date' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="background-container-white">
  <div class="content-container content-container-white content-container-spaced">
    <div class="flex-content">
      <div class="flex-content-text">
        <div class="icon-header margin-bottom">
          <img src="../../assets/icons/user-plus.svg">
        </div>
        <h2>{{ 'HOME.advantages_members_title' | translate }}</h2>
        <p class="paragraph">{{ 'HOME.advantages_members_subtitle' | translate }}</p>
      </div>
      <div class="flex-content-image">
        <div class="users-container">
          <div class="box box-center">
            <img src="../../assets/images/senior.jpeg" class="box-senior-img">
            <p class="paragraph">Jaqueline Pelletier</p>
          </div>
          <div class="box">
            <p class="label">{{ 'HOME.advantages_members_label' | translate }} <span class="subtitle">(4/8)</span></p>

            <div class="box-users-container">
              <div class="user-item">
                <img src="../../assets/images/default-user.png">
                <p class="paragraph">Marc Pelletier</p>
              </div>
              <div class="user-item">
                <img src="../../assets/images/default-user.png">
                <p class="paragraph">Julie Pelletier</p>
              </div>
              <div class="user-item">
                <img src="../../assets/images/default-user.png">
                <p class="paragraph">Leo Pelletier</p>
              </div>
              <div class="user-item">
                <img src="../../assets/images/default-user.png">
                <p class="paragraph">Oliver Chase</p>
              </div>
            </div>

            <div class="plus-icon">
              <img src="../../assets/icons/plus.svg" alt="plus-icon">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Senior's features -->

<div class="background-container-orange">
  <div class="content-container content-container-orange content-container-spaced">
    <div class="hero-container hero-container-padding">
      <h2>{{ 'HOME.features_title' | translate }}</h2>
      <p class="paragraph">{{ 'HOME.features_subtitle' | translate }}</p>
    </div>

    <div class="device-boxs">
      <div class="box box-flex">
        <img class="select-flex-icon-orange" src="../../assets/icons/image.svg" alt="icon-image">
        <p class="paragraph">{{ 'HOME.feature_mode' | translate }}</p>
        <p class="subtitle-big">{{ 'HOME.feature_mode_subtitle' | translate }}</p>
      </div>

      <div class="box box-flex">
        <img class="select-flex-icon-orange" src="../../assets/icons/wrench.svg" alt="icon-wrench">
        <p class="paragraph">{{ 'HOME.feature_permissions' | translate }}</p>
        <p class="subtitle-big">{{ 'HOME.feature_permissions_subtitle' | translate }}</p>
      </div>

      <div class="box box-flex">
        <img class="select-flex-icon-orange" src="../../assets/icons/moon.svg" alt="icon-moon">
        <p class="paragraph">{{ 'HOME.feature_night_mode' | translate }}</p>
        <p class="subtitle-big">{{ 'HOME.feature_night_mode_subtitle' | translate }}</p>
      </div>

      <div class="box box-flex">
        <img class="select-flex-icon-orange" src="../../assets/icons/sound.svg" alt="icon-sound">
        <p class="paragraph">{{ 'HOME.feature_sounds' | translate }}</p>
        <p class="subtitle-big">{{ 'HOME.feature_sounds_subtitle' | translate }}</p>
      </div>
    </div>
  </div>
</div>

<!-- Footer -->

<div class="content-container content-container-footer">
  <div class="hero-container">
    <h1>{{ 'HOME.box_footer_title' | translate }}</h1>
    <p class="paragraph">{{ 'HOME.box_footer_paragraph' | translate }}</p>
    <img [src]="'../../assets/svgs/download-apple-' + theme + '.svg'" alt="download-apple" class="img-download">
  </div>
</div>
